<template>

  <div id="root">

    <vl-map id="map" :load-tiles-while-animating="true" :load-tiles-while-interacting="true" data-projection="EPSG:4326"
      style="height: 100vh" ref="map">

      <vl-view :center.sync="center" :zoom.sync="zoom" :resolution.sync="resolution" ref="mapView"></vl-view>

      <vl-layer-tile id="basemap">
        <vl-source-osm :attributions="attribution" :url="url"></vl-source-osm>
      </vl-layer-tile>

      <vl-layer-vector>
        <vl-source-vector @addfeature="viewAddFeature" :url="view_url" v-bind:formatFactory="getOSMXMLFmt"
          ref="xmlmarkers" />
        <vl-style-func ref="mapStyle" :function="objectStyle" />
      </vl-layer-vector>

      <vl-interaction-select :features.sync="selected" :toggle-condition="never" :filter="selectFilter"
        :use-feature-style="false">
        <vl-style-box>
          <vl-style-func :function="selectedObjectStyle" />
        </vl-style-box>
      </vl-interaction-select>

      <vl-interaction-select :features.sync="hovered_feature" :condition="pointer_move" :toggle-condition="touch_only"
        :use-feature-style="true" :multi="true">
      </vl-interaction-select>
    </vl-map>

    <div class="prompt block-prompt" v-if="orchard_defined">
      <h4>No Orchard Selected.</h4>
      <a>Choose an orchard and a scan from the menus in the top right.</a>
    </div>

    <!-- <div class="prompt print-prompt">
      <form class="form">
      <label for="format">Page size </label>
      <select id="format">
        <option value="a0">A0 (slow)</option>
        <option value="a1">A1</option>
        <option value="a2">A2</option>
        <option value="a3">A3</option>
        <option value="a4" selected>A4</option>
        <option value="a5">A5 (fast)</option>
      </select>
      <label for="resolution">Resolution </label>
      <select id="resolution">
        <option value="72">72 dpi (fast)</option>
        <option value="150">150 dpi</option>
        <option value="300">300 dpi (slow)</option>
      </select>
    </form>
    <button id="export-pdf" @click="submit">Export PDF</button>
    </div> -->

    <div class="prompt print-prompt">
      <form class="form" @submit.prevent="submit">
        <label for="format">Page size</label>
        <select id="format" v-model="form.size">
          <option value="a0">A0 (slow)</option>
          <option value="a1">A1</option>
          <option value="a2">A2</option>
          <option value="a3">A3</option>
          <option value="a4" selected>A4</option>
          <option value="a5">A5 (fast)</option>
        </select>
        <label for="resolution">Resolution</label>
        <select id="resolution" v-model="form.resolution">
          <option value="72">72 dpi (fast)</option>
          <option value="150">150 dpi</option>
          <option value="300">300 dpi (slow)</option>
        </select>
        <button type="submit">Export PDF</button>
      </form>
    </div>

    <transition name="fade">
      <div class="gradient-hov-2" v-if="!gradient_small" @mouseover="gradient_hovered = true"
        @mouseleave="gradientMouseLeave" v-click-outside="gradientLeaveTimout">
        <div class="slider">
          <vue-slider ref="slider" v-model="value" :process-style="process_style" :rail-style="railStyle"
            :dot-options="dot_options" :tooltip-placement="'bottom'">
          </vue-slider>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="gradient-key-2" v-show="gradient_small" @click="mouseTest"
        @mouseover="key_hovered = true, gradient_small = false" @mouseleave="key_hovered = false"
        v-click-outside="gradientLeaveTimout">
        <div class="key-item left">
          {{ value[0] }}
        </div>
        <div class="key-item middle">
          {{ value[1] }}
        </div>
        <div class="key-item right">
          {{ value[2] }}
        </div>
      </div>
    </transition>

    <div class="panel-group">

      <transition-group name="panel-slide" id="panel-transition">

        <div id="panel-sel" v-show="preview_shown" key="panel-sel">
          <div class="flex-col">

            <div class="info-grid">

              <div class="info-item block">
                <div>
                  <div class="title">
                    {{ selected_details['block']['name'] }}
                  </div>
                  <div class="average" :style="selected_block_style">
                    density
                    <span>
                      {{ selected_details['block']['average'] }}/m²</span>
                  </div>
                </div>
                <div class="stats">
                  <div class="count" v-html="selected_details['block']['count']"></div>
                  <div class="area" v-html="selected_details['block']['area']"></div>
                </div>
              </div>

              <span class="vert-divider"></span>

              <div class="info-item">
                <div>
                  <div class="title">
                    Bay {{ selected_details['bay']['name'] }}
                  </div>
                  <div class="average" :style="selected_bay_style">
                    density
                    <span>{{ selected_details['bay']['average'] }}/m²</span>
                  </div>
                </div>
                <div class="stats">
                  <div class="count" v-html="selected_details['bay']['count']"></div>
                  <div class="area" v-html="selected_details['bay']['area']"></div>
                </div>
              </div>

              <span class="vert-divider"></span>

              <div class="info-item">
                <div>
                  <div class="title">
                    Row {{ selected_details['row']['name'] }}
                  </div>
                  <div class="average" :style="selected_row_style">
                    density
                    <span>{{ selected_details['row']['average'] }}/m²</span>
                  </div>
                </div>
                <div class="stats">
                  <div class="count kiwifruit" v-html="selected_details['row']['count']">
                  </div>
                  <div class="area" v-html="selected_details['row']['area']">
                  </div>
                </div>
              </div>

            </div>

            <img class="bay-img" width="100%" height="auto" @load="onImgLoad(this)" @error="onImgError()" :src=bay_image />

          </div>

        </div>

        <div id="panel-subgroup" key="hover">

          <div id="panel-hov" v-if="hoveredDetails['row']">
            <div class="info-grid">

              <div class="info-item" v-if="hoveredDetails['bay']">
                <div class="title">
                  Bay {{ hoveredDetails['bay']['name'] }}
                </div>
                <div class="average" :style="hovered_bay_style">
                  density
                  <!-- TODO icon missing -->
                  <span>{{ hoveredDetails['bay']['average'] }}/m²</span>
                </div>
                <div class="stats">
                  <div class="count" v-html="hoveredDetails['bay']['count']">
                  </div>
                  <div class="area" v-html="hoveredDetails['bay']['area']"> </div>
                  <!-- TODO remove location? -->
                </div>
              </div>

              <span class="vert-divider" v-if="hoveredDetails['bay']"></span>

              <div class="info-item">
                <div class="title">
                  Row {{ hoveredDetails['row']['name'] }}
                </div>
                <div class="average" :style="hovered_row_style">
                  density
                  <!-- TODO add js style in this section. -->
                  <!-- TODO change average to density -->
                  <span> {{ hoveredDetails['row']['average'] }} </span>
                </div>
                <div class="stats">
                  <div class="count" v-html="hoveredDetails['row']['count']">
                  </div>
                  <div class="area" v-html="hoveredDetails['row']['area']">
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </transition-group>

    </div>

  </div>
</template>

<!-- ------------------------------------------------------------------------------------------------------------------------ -->

<script>
import Vue from "vue";
import { Slider } from 'element-ui'
import VueSlider from 'vue-slider-component'
import VueLayers from 'vuelayers'
import { IconStyle } from 'vuelayers'
import 'vuelayers/dist/vuelayers.css'
import 'vue-slider-component/theme/antd.css'
import { default as OSMXML } from 'ol/format/OSMXML'
import { Style, Fill, Text, Stroke, Circle } from 'ol/style'
import { getArea, getLength } from 'ol/sphere'
import { never, pointerMove, touchOnly, dragging } from 'ol/events/condition'
import { toStringXY } from 'ol/coordinate'
import { getCenter } from 'ol/extent'
import { toLonLat } from 'ol/proj'
import { defaults as controlDefaults, ScaleLine, Zoom, Attribution } from 'ol/control'
import { SlideXLeftTransition } from 'vue2-transitions'
import mapApi from '../../../../../public/static/map-api.json'
import { setPageRules } from '../../../../../public/static/customizePageStyle.js'
import { jsPDF } from "jspdf"

// import Map from 'ol/Map.js';
// import View from 'ol/View.js';
import WKT from 'ol/format/WKT.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';

// import orchardList from '../../../../../orchard-list.json'
import VueHtmlToPaper from 'vue-html-to-paper'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

const dims = {"a0": [1189, 841], "a1": [841, 594], "a2": [594, 420], "a3": [420, 297], "a4": [297, 210], "a5": [210, 148],};

Vue.use(VueLayers)
const controlsArray = controlDefaults().extend([
  new ScaleLine()
])

Vue.use(VueHtmlToPaper, options);
// Vue.use(IconStyle);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
  },
  unbind: function (el) {
  },
});

export default {
  created() {
    this.$root.$on('my-event', () => {
      console.log('my-event received');
    });
  },
  components: {
    [Slider.name]: Slider,
    VueSlider,
    SlideXLeftTransition,
  },
  props: {
    path: { type: String },
  },
  data() {
    return {
      form: {
        size: 'a4',
        resolution: '72',
      },
      dims: {
        "a0": [1189, 841], "a1": [841, 594], "a2": [594, 420],
        "a3": [420, 297], "a4": [297, 210], "a5": [210, 148],
      },
      printDuplicates: [],
      gradient_small: true,
      gradient_hovered: false,
      key_hovered: false,
      process_style: { "opacity": "0" }, // TODO hard code into CSS?
      dot_options: [
        {
          style: {
            "backgroundColor": "rgb(2255, 250, 0)",
            "border": "2px solid #fffb00"
          },
          focusStyle: {
            "boxShadow": "0 0 0 5px rgba(255, 250, 0, 0.2)"
          },
          tooltip: 'always'
        },
        {
          style: {
            "backgroundColor": "rgb(20, 255, 0)",
            "border": "2px solid #11ff00"
          },
          focusStyle: {
            "boxShadow": "0 0 0 5px rgba(20, 255, 0, 0.2)"
          },
          tooltip: 'always'
        },
        {
          style: {
            "backgroundColor": "rgb(0, 30, 255)",
            "border": "2px solid rgb(0, 30, 255)"
          },
          focusStyle: {
            "boxShadow": "0 0 0 5px rgba(0, 30, 255, 0.2)"
          },
          tooltip: 'always'
        }
      ],
      center: [0, 0],
      zoom: null,
      resolution: null,
      selected: [],
      selected_details: {
        'block': {
          'name': 'undefined',
          'average': '0/m2',
          'area': '0m<sup>2</sup>',
          'location': '0.0,<br/>0.0',
          'count': '0',
          'color': 'rgb(0, 0, 0)'
        },
        'row': {
          'name': 'undefined',
          'average': '0/m2',
          'area': '0m<sup>2</sup>',
          'location': '0.0,<br/>0.0',
          'count': '0',
          'color': 'rgb(0, 0, 0)'
        },
        'bay': {
          'name': 'undefined',
          'average': '0/m2',
          'area': '0m<sup>2</sup>',
          'location': '0.0,<br/>0.0',
          'count': '0',
          'color': 'rgb(0, 0, 0)'
        }
      },
      hovered_feature: [],
      preview_shown: false,
      bay_image: '',
      attribution: 'Basemap: <a href="https://basemaps.linz.govt.nz">&copy; CC BY 4.0 LINZ</a> Orchard Data: <a href="https://acurissystems.com"> Acuris Systems</a> t5.7',
      url: `https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.png?api=${mapApi['key']}`,
      state: false, // TODO set but never read from?
      never: never,
      pointer_move: pointerMove,
      touch_only: touchOnly,
      value: [25, 50, 75], // TODO rename? starting gradient key value
    }
  },
  computed: {
    print_size() {
      let w = window.innerWidth;
      let h = window.innerHeight;
      if (w > (h * 1.414)) {
        w = h * 1.414;
      } else {
        h = w / 1.414;
      }
      // let w = window.innerHeight * 1.414;
      return `${w}px ${h}px`
    },
    print_offset() {
      return `${window.innerHeight / 2}px`
    },
    // print_margins() {
    //   return '0'
    // },
    orchard_defined() {
      console.log("orchard defined:", this.$root.orchard.name)
      if (this.$root.orchard.name) {
        return false
      } else {
        return true
      }
    },
    selected_block_style() {
      return { "background-color": this.setOpacity(this.selected_details["block"]["color"], 0.3) }
    },
    selected_row_style() {
      return { "background-color": this.setOpacity(this.selected_details["row"]["color"], 0.3) }
    },
    selected_bay_style() {
      return { "background-color": this.setOpacity(this.selected_details["bay"]["color"], 0.3) }
    },
    hovered_row_style() {
      // console.log("hovered_row_style: this.hoveredDetails:", this.hoveredDetails)
      return { "background-color": this.setOpacity(this.hoveredDetails["row"]["color"], 0.3) }
    },
    hovered_bay_style() {
      return { "background-color": this.setOpacity(this.hoveredDetails["bay"]["color"], 0.3) }
    },
    view_url() {
      // console.log("this.$root.orchard.value:", this.$root.orchard.value)
      // console.log(`view url: https://orchard.acurissystems.com/${this.$root.orchard.value}.xml`)
      console.log(`view url: https://orchard.acurissystems.com/${this.$root.scan.xml_map}.xml`)
      return `https://orchard.acurissystems.com/${this.$root.scan.xml_map}.xml`
      // return `https://orchard.acurissystems.com/oaklands-2.xml`
    },
    clrs() {
      return [['rgba(255,0,0,0.5)', 0], ['rgb(255,213,0,0.5)', this.value[0]], ['rgb(0,255,0,0.5)', this.value[1]], 
      ['rgba(0,0,255,0.5)', this.value[2]], ['rgb(200,0,255,0.5)', 100]]
    },
    railStyle() {
      let grad_str = "linear-gradient(90deg"
      for (let i of this.clrs) {
        let clr = i[0]
        let val = i[1]
        grad_str += ", " + clr + " " + val + "%"
      }
      grad_str += ")"
      return {
        "background": grad_str
      }
    },
    scan() {
      return this.$root.scan
    },
    hoverStyle() {
      if (this.preview_shown) {
        return {
          "left": '24.4vw'
        }
      }
      return {
        "left": '0'
      }
    },
    hoveredDetails() {
      let ts = this
      let features = this.hovered_feature
      if (features.length === 0) {
        return {};
      }

      features = features.map(function (f) { return ts.$refs.xmlmarkers.getFeatureById(f.id) })
      let parsed_features = this.details(features)
      return parsed_features
    },
  },
  watch: {
    value: function (vs) {
      console.log("this.$refs.map.$map :: <---------")
      console.log(this.$refs.map.$map)
      let vl = this.$refs.map.$map.getLayers().item(1)

      console.log("vl:", vl);
      vl.getSource().changed()
    },
    selected: function (v) {
      console.log("v:", v)
      console.log("this.$root.scan:", this.$root.scan)
      let selected = v[0]
      if (v.length == 0) {
        this.preview_shown = false
        this.setButtonHeight() // make sure to set button height before function returns
        return null
      }
      let centroid = this.centroidFromCoords(selected.geometry.coordinates[0])
      let heirarchy = this.$refs.xmlmarkers.getFeaturesAtCoordinate(centroid)
      // console.log("selected: heirarchy:", heirarchy)
      let dets = this.details(heirarchy)
      console.log("selected: dets:", dets)

      let orchard_id = dets["orchard"]["name"].replace(/ /g, "-").toLowerCase()
      let block_id = dets["block"]["name"].replace(/ /g, "-").toLowerCase()
      console.log("selected: this.$root.scan:", this.$root.scan)
      let scan_path = "undefined"
      if (this.$root.scan.value) {
        console.log("this.$root.scan.value truthy")
        let scan_parts = this.$root.scan.value.split(":")
        console.log("scan_parts:", scan_parts)
        if (scan_parts.length > 1) {
          scan_path = scan_parts[1]
        } else {
          scan_path = scan_parts[0]
        }
      }
      let bay_url = `https://orchard.acurissystems.com/scans/${orchard_id}/${scan_path}/${block_id}/${dets["row"]["name"].padStart(2, '0')}/${dets["bay"]["name"].padStart(2, '0')}.jpg`
      bay_url = bay_url.toLowerCase() // TODO make sure lowercasing working
      this.bay_image = bay_url

      dets["block"]["name"] = this.capitilizeFirst(dets["block"]["name"])

      this.selected_details = dets
      this.preview_shown = true
      this.setButtonHeight()
    },
  },
  mounted() {
    // log some basic variables to the console
    console.log('mounted: mapApi:', mapApi, "path:", this.path)

    // create reference to this vue component in root
    this.$root.$refs.LandingMap = this

    // add click event listener to export pdf button

    // let exportButton = document.getElementById('export-pdf');
    // console.log(exportButton)

    // exportButton.addEventListener('click', function () {
    //   exportButton.disabled = true;
    //   document.body.style.cursor = 'progress';
    //   const format = document.getElementById('format').value;
    //   const resolution = document.getElementById('resolution').value;

    //   console.log("export listener: format:", format, typeof(format))
    //   console.log("export listener: resolution:", resolution, typeof(resolution))
    //   console.log("export listener: dims:", dims)

    //   const dim = dims[format];
    //   const width = Math.round((dim[0] * resolution) / 25.4);
    //   const height = Math.round((dim[1] * resolution) / 25.4);

    //   console.log("export listener: width height:", width, height)

    //   // let ol_map = this.$refs.map.$map

    //   console.log("export listener: this.$refs.map:", this.$refs.map)
    //   // console.log("export listener: ol_map:", ol_map)

    //   // const size = ol_map.getSize();
      
    //   // console.log("export listener: size:", size)

    //   // const viewResolution = map.getView().getResolution();

    //   console.log("reached function mark")
    // });

    console.log("this.print_size:", this.print_size)

    // create print listeners
    window.addEventListener('beforeprint', async e => {
      await setPageRules.adds([
        {ruleName: 'size', ruleValue: this.print_size},
        {ruleName: 'margin', ruleValue: '0'},
      ]);
    });
    window.addEventListener('afterprint', e => setPageRules.clean());

    // create window resize listener
    window.addEventListener("resize", this.setButtonHeight);
    // and 
    window.addEventListener('scan_changed', this.scanChanged);
    window.addEventListener('scan_changed', function() {
      console.log("scan changed captured")
    });
    this.$parent.$on('scan_changed', () => {
      console.log('-------> my-event received');
    });

    // ASYNC set openlayers map and controls
    this.createOpenlayersControls()

    // TODO rename r and json to more readable names
    fetch("https://orchard.acurissystems.com/orchard-list.json") //retrieve orchards json
    .then(r => r.json()) // format orchards json
    .then(json => {

      // json = orchardList // DEV replace json with local
      // console.log("local json:", json)

      let formatted_orchards_list = this.formatOrchardsJson(json) // format json
      console.log("formatted_orchards_list:", formatted_orchards_list)

      let path_params = this.parsePath(this.path) // parse url path
      // console.log("path_params:", path_params)

      let orchard_scan_matches = this.matchOrchardScan(formatted_orchards_list, path_params); // match orchards and scans
      let selected_orchard = orchard_scan_matches.selected_orchard, selected_scan = orchard_scan_matches.selected_scan; // set to variables
      console.log("selected_orchard:", selected_orchard)
      console.log("selected_scan:", selected_scan)

      // commit variables to root
      this.$root.orchard_list = formatted_orchards_list;
      if (selected_orchard) { 
        this.$root.orchard = selected_orchard
        this.$root.scan_list = selected_orchard.scan_list
        if (selected_scan) {
          this.$root.scan = selected_scan
        }
      }
    });
  },
  methods: {
    submit() {
      console.log("submit triggered:", this.form)

      // exportButton.disabled = true; // TODO
      document.body.style.cursor = 'progress';

      // const format = document.getElementById('format').value;
      let format = this.form.size;
      // const resolution = document.getElementById('resolution').value;
      let resolution = this.form.resolution;
      // const dim = dims[format];
      let dim = dims[format];
      let width = Math.round((dim[0] * resolution) / 25.4);
      let height = Math.round((dim[1] * resolution) / 25.4);

      console.log(format, resolution, dim, width, height);
      // console.log(this.$refs)

      let map = this.$refs.map.$map;

      let size = map.getSize();
      let viewResolution = map.getView().getResolution();

      map.once('rendercomplete', function () {
        const mapCanvas = document.createElement('canvas');
        mapCanvas.width = width;
        mapCanvas.height = height;
        const mapContext = mapCanvas.getContext('2d');

        Array.prototype.forEach.call(
          document.querySelectorAll('.ol-layer canvas'),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              const transform = canvas.style.transform;
              // Get the transform parameters from the style's transform matrix
              const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );

        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);
        // const pdf = new jspdf.jsPDF('landscape', undefined, format);
        const pdf = new jsPDF({orientation: "landscape"});
        pdf.addImage(mapCanvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, dim[0], dim[1]);
        pdf.save('map.pdf');
        // Reset original map size
        map.setSize(size);
        map.getView().setResolution(viewResolution);
        // exportButton.disabled = false; // TODO
        document.body.style.cursor = 'auto';
      });
      // Set print size
    const printSize = [width, height];
    map.setSize(printSize);
    const scaling = Math.min(width / size[0], height / size[1]);
    map.getView().setResolution(viewResolution / scaling);
    //   },
    // false
    },
    scanChanged() {
      console.log("scanChanged::")
      this.preview_shown = false
      this.selected_details = {}
    },
    printOnce(str) {
      if (!this.printDuplicates.some(e => (e === str))) {
        this.printDuplicates.push(str)
        console.log(str)
      }
    },
    matchOrchardScan(formatted_orchards_list, path_params) {
      // check for orchard matching path param
      let selected_orchard = null;
      let selected_scan = null
      // console.log("path parameters:", path_params)
      if (path_params) {
        for (let i = 0; i < formatted_orchards_list.length; i++) {
          if (formatted_orchards_list[i].value === path_params[0]) {
            selected_orchard = formatted_orchards_list[i];
            break;
          }
        }
        // console.log("selected_orchard:", selected_orchard)

        // if orchard match was found, and scan param was supplied in path, search for scan value matching path param
        
        if (selected_orchard) { // if orchard match is found / is truthy:
          if (path_params.length == 2) {
            for (let i = 0; i < selected_orchard.scan_list.length; i++) {
              if (selected_orchard.scan_list[i].value === path_params[1]) {
                selected_scan = selected_orchard.scan_list[i]
              }
            }
          }
          if (!selected_scan) { // if no param supplied or no match found, instead find most recent scan by date
            selected_scan = selected_orchard.scan_list[0]
            for (let i = 1; i < selected_orchard.scan_list.length; i++) { // loop through scans past 1
              if (this.moreRecent(selected_orchard.scan_list[i], selected_scan)) {
                selected_scan = selected_orchard.scan_list[i]
              }
            }
          }
        }
      }
        return { selected_orchard, selected_scan }
      },
      formatOrchardsJson(json) {
        let orchard_list = []
        for (let i = 0; i < json.orchards.length; i++) { // loop through orchards
          let c_orchard = {"value": json.orchards[i].value, "name": json.orchards[i].name, "scan_list": []} // create orchard dict entry
          for (let j = 0; j < json.orchards[i].xml_maps.length; j++) { // loop through xml maps
            let c_map_value = json.orchards[i].xml_maps[j].value
            for (let k = 0; k < json.orchards[i].xml_maps[j].scans.length; k++) { // loop through map scans
              // TODO parse type into icon
              let c_json_scan = json.orchards[i].xml_maps[j].scans[k]
              let type_icon = `icon-${c_json_scan.type}`
              let c_scan = {"value": c_json_scan.value, "name": c_json_scan.name, "icon": type_icon, "date": c_json_scan.date, "format": c_json_scan.format, "xml_map": c_map_value}
              c_orchard.scan_list.push(c_scan)
            }
          }
          orchard_list.push(c_orchard)
        }
      return orchard_list
    },
    createOpenlayersControls() {
      this.$refs.map.$createPromise.then(() => {
        this.$refs.map.$map.addControl(new ScaleLine)
        let ol_map = this.$refs.map.$map
        ol_map.getControls().forEach(function (control) {
          if (control instanceof Attribution) {
            ol_map.removeControl(control)
            let attribution = new Attribution({collapsible: true})
            ol_map.addControl(attribution)
          }
          if (control instanceof Zoom) {
            ol_map.removeControl(control)
            ol_map.addControl(new Zoom({ "delta": 0.5 }))
          }
        });
      })
    },
    parsePath(path) { // TODO write function comments here not on each line
      // console.log("parsePath: path:", path)
      let decoded_path = decodeURIComponent(path)
      console.log("parsePath: decoded path:", decoded_path)
      let param_path = decoded_path.split('landing-map/')[1] // split url and take string after page section
      if (param_path) { // if there is a valid string after page section
        let split_params = param_path.split('/') // split that string
        if (split_params.length < 3) { // if the list of strings is less than 3
          console.log("parsePath: returning valid:", split_params) // 
          return split_params
        } else {
          console.log("parsePath: returning invalid empty array")
          return []
        }
      }
    },
    setButtonHeight() {
      let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      let height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
      let overlayContainer = this.$refs.map.$map.overlayContainerStopEvent_
      if (width < height) {
        if (this.preview_shown) {
          overlayContainer.style.bottom = "40vh"
        } else {
          overlayContainer.style.bottom = "0"
        }
      } else {
        console.log("lowering buttons")
        overlayContainer.style.bottom = "0"
      }
      console.log("windowWidth: window.innerWidth", window.innerWidth)
      console.log("windowHeight: window.innerWidth", window.innerHeight)
    },
    setOpacity(color_str, opacity) {  // helper function that takes a css color string and changes/sets the opacity
      let color_parts = color_str.split(",")
      let return_suffix = `, ${opacity})`
      let return_prefix = ""
      if (color_parts.length < 4) {
        return_prefix = color_str.slice(0, -1)
      } else {
        return_prefix = `${color_parts[0]},${color_parts[1]},${color_parts[2]}`
      }
      // console.log("setOpacity: returning:", return_prefix + return_suffix)
      return return_prefix + return_suffix
    },
    onImgError() { // replac
      this.bay_image = '/img/icons/bayimagenotfound.jpg'
    },
    onImgLoad(e) { // TODO being used? seem to 
      console.log("onImgLoad: e:", e)
    },
    mouseTest(event) {
      console.log("---------------------------------------------------------<")
    },
    moreRecent(date_str_a, date_str_b) { // Is A more recent than B
      let date_a = Date.parse(date_str_a)
      let date_b = Date.parse(date_str_b)
      if (date_a > date_b) {
        return true
      }
      return false
    },
    // tileClick() {
    //   console.log("tileClick--------!")
    //   this.gradientLeaveTimout(0)
    // },
    parseOrchard(orchard_value) {
      let orchard_name = this.capitilizeFirstAll(orchard_value.replace("-", " "))
      return { "value": orchard_value, "name": orchard_name }
    },
    scanRaw2Dict(scan_raw) {
      let scan_parts = scan_raw.split(":")
      if (scan_parts.length < 2) {
        return {"value": scan_parts[0], "icon": "icon-pin"}
      } else {
        let scan_icon = `icon-${scan_parts[0]}`
        return {"value": scan_parts[1], "icon": scan_icon}
      }
    },
    scanDict2Raw(scan_dict) {
      let type = scan_dict.icon.split("-")[1]
      return `count:${type}:${scan_dict.value}`
    },
    parseScan1(scan_value) {
      let scan_parts = scan_value.split(":")
      if (scan_parts.length < 2) {
        return this.parseScan3(scan_parts[0], scan_parts[0], 'pin')
      } else {
        return this.parseScan3(scan_value, scan_parts[1], scan_parts[0])
      }
    },
    parseScan2(scan_name, scan_type) {
      let scan_value = scan_type + ":" + scan_name
      return this.parseScan3(scan_value, scan_name, scan_type)
    },
    parseScan3(scan_value, scan_name, scan_type) {
      let scan_icon = `icon-${scan_type}`
      return { "value": scan_value, "name": scan_name, "icon": scan_icon }
    },
    gradientMouseLeave() {
      this.gradient_hovered = false
      setTimeout(this.gradientLeaveTimout, 500)
    },
    gradientLeaveTimout(i) {
      if (this.key_hovered || this.gradient_hovered) {
        this.gradient_small = false
        return
      }
      this.gradient_small = true
    },
    capitilizeFirst(s) {
      return s[0].toUpperCase() + s.substring(1)
    },
    capitilizeFirstAll(s) {
      let s_parts = s.split(" ")
      let s_final = ""
      for (let c_part in s_parts) {
        s_final += this.capitilizeFirst(s_parts[c_part]) + " "
      }
      return s_final.slice(0, -1) // remove final space
    },
    centroidFromCoords(coords) {
      let ps = []
      for (let i of [[0, 1, 3], [1, 2, 3]]) {
        let px = 0
        let py = 0
        for (let j of i) {
          px += coords[j][0] / 3
          py += coords[j][1] / 3
        }
        ps.push([px, py])
      }
      let centroid = [(ps[0][0] + ps[1][0]) / 2, (ps[0][1] + ps[1][1]) / 2]
      return centroid
    },
    details(features) {
      let dets = {}
      let typeList = ['orchard', 'block', 'row', 'bay']
      for (let typeStr of typeList) {
        let feature = features.filter(f => f.get('acuris') === typeStr).filter(n => n !== undefined)[0] // TODO fix get null error
        // console.log(feature)
        if (feature == undefined) {
          dets[typeStr] = undefined
          continue
        }
        dets[typeStr] = { 'name': feature.get('name') }
        let locationStr = toStringXY(toLonLat(getCenter(feature.getGeometry().getExtent())), 8)
        dets[typeStr]['location'] = '<span class="icon-pin"></span> ' + locationStr.replace(' ', '<br/>')

        let area = parseFloat(feature.get('area'))
        if (isNaN(area)) {
          dets[typeStr]['area'] = '<span class="icon-area"></span> -'
        } else if (area < 10000) {
          dets[typeStr]['area'] = `<span class="icon-area"></span> ${area.toFixed(1)} m&sup2; `;
        } else {
          dets[typeStr]['area'] = `<span class="icon-area"></span> ${(area / 10000).toFixed(1)} ha`;
        }
        let countStr = feature.getKeys().filter(k => k.startsWith("count:"))
        // console.log("countStr:", countStr)
        let count = undefined
        for (let c_count of countStr) {
          // console.log("c_count:", c_count)
          // console.log("c_count:", c_count.split(":"))
          let c_count_parts = c_count.split(":")
          let c_count_value = c_count_parts[c_count_parts.length - 1]
          // console.log("c_count_value:", c_count_value)
          if (c_count_value === this.$root.scan.value) {
            count = parseFloat(feature.get(c_count))
          }
        }
        // let count = parseFloat(feature.get(countStr))
        // console.log("count:", count)
        if (isNaN(count)) {
          dets[typeStr]['count'] = "-"
          dets[typeStr]['average'] = "-"
          dets[typeStr]['color'] = "rgb(0, 0, 0)"
        } else {
          dets[typeStr]['count'] = `<span class="${this.$root.scan.icon}"></span>${this.kFormat(count)}`
          dets[typeStr]['average'] = (parseFloat(count) / area).toFixed(1)
          dets[typeStr]['color'] = this.setOpacity(this.countColor(count / area), 1)
        }
      }
      return dets
    },
    round(i, n) {
      return +(Math.round(i + `e+${n}`) + `e-${n}`);
    },
    kCommarInsert(i) {
      let sp = i.toString().split(".")
      let s = sp[0]
      let d = sp[1]
      let t = 3 //target/threshold value to add commar
      while (s.length > t) {
        s = s.slice(0, -t) + "," + s.slice(-t)
        t += 4
      }
      if (sp.length > 1) {
        s = s + "." + sp[1]
      }
      return s
    },
    kFormat(i) {
      let k = false
      if (i >= 10000) {
        i = this.round(i / 1000, 2)
        k = true
      }
      i = this.kCommarInsert(i)
      if (k) {
        return `${i}k`
      }
      return `${i}`
    },
    selectFilter(f) {
      return f.get('acuris') === 'bay'
    },
    getOSMXMLFmt() {
      this.state = true;
      return new OSMXML({
      })
    },
    viewAddFeature(event) {
      // this.printOnce("feature added: " + event.feature.get('acuris'))
      if (event.feature.get('acuris') === "bay") {
        // console.log("bay feature::");
        // console.log(event);
        let test_scans = event.feature.getKeys().filter(k => k.startsWith("count:kiwifruit:2023-01-1"))
        for (let test_c_scan of test_scans) {
          console.log("test_c_scan:", test_c_scan)
          console.log(event);
          // this.printOnce("scan feature added: " + c_scan)
        }
      }
      if (event.feature.get('acuris') === "orchard") {
        console.log("orchard feature added. making changes");
        // TODO change window title to change when orchard selected instead
        window.document.title = event.feature.get('name').replace(/^\w/, c => c.toUpperCase()) + ' Scan';
        let padding = 50;
        this.$refs.mapView.fit(event.feature.getGeometry(), { "padding": Array(4).fill(padding) })
      }

      let scans = event.feature.getKeys().filter(k => k.startsWith("count:"))
      for (let c_scan of scans) {
        // console.log("scan feature added: " + c_scan)
        this.printOnce("scan feature added: " + c_scan)
        let c_parts = c_scan.split("count:")
        if (c_parts.length > 1) {
          // c_scan = this.parseScan1(c_parts[1])
          c_scan = this.scanRaw2Dict(c_parts[1])
        }
        if (!this.$root.scan_list.some(e => (e.value === c_scan.value))) {
          c_scan["name"] = c_scan.value
          this.$root.scan_list.push(c_scan)
        }
      }

      let area = event.feature.get('area')
      if (area === undefined) {
        area = getArea(event.feature.getGeometry())
        event.feature.set('area', area.toString(), true);
      }
    },
    colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
      let color1 = colorToRGBA(rgbColor1);
      let color2 = colorToRGBA(rgbColor2);
      let fade = fadeFraction;

      if (rgbColor3) {
        fade = fade * 2;

        if (fade >= 1) {
          fade -= 1;
          color1 = color2;
          color2 = colorToRGBA(rgbColor3);
        }
      }

      let diffRed = color2.red - color1.red;
      let diffGreen = color2.green - color1.green;
      let diffBlue = color2.blue - color1.blue;
      let diffAlpha = color2.alpha - color1.alpha;

      let gradient = {
        red: Math.floor(color1.red + (diffRed * fade)).toFixed(0),
        green: Math.floor(color1.green + (diffGreen * fade)).toFixed(0),
        blue: Math.floor(color1.blue + (diffBlue * fade)).toFixed(0),
        alpha: Math.floor(color1.alpha + (diffAlpha * fade)) / 256,
      };

      return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ',' + gradient.alpha + ')';
    },
    countColor(count) {
      let clrs = this.clrs
      for (let i in clrs) {
        if (count < clrs[i][1]) {
          if (i === "0") {
            return clrs[0][0]
          } else {
            let min = clrs[i - 1]
            let max = clrs[i]
            return this.colorGradient((count - min[1]) / (max[1] - min[1]), min[0], max[0])
          }
        }
      }
      return clrs[clrs.length - 1][0]
    },
    getScanName(scan_raw) {
      let scan_parts = scan_raw.split(":")
      return scan_parts[scan_parts.length - 1]
    },
    createTextStyle(text, resolution, placement = 'point', color = 'black') {
      let align = 'center';
      let baseline = 'middle';
      let size = '10px';
      let height = '1';
      let offsetX = 0;
      let offsetY = 0;
      let weight = 'bold';

      let maxAngle = 0.7853981633974483;
      let overflow = true;
      let rotation = 0.0;
      let font = weight + ' ' + size + '/' + height + ' ' + 'Verdana';
      let fillColor = color;
      // let fillColor = 'black';

      return new Text({
        textAlign: align === '' ? undefined : align,
        textBaseline: baseline,
        font: font,
        text: text,
        fill: new Fill({ color: fillColor }),

        offsetX: offsetX,
        offsetY: offsetY,
        placement: placement,
        maxAngle: maxAngle,
        overflow: overflow,
        rotation: rotation,
        scale: [1 / resolution, 1 / resolution]
      });
    },
    selectedObjectStyle(feature) {
      return this.objectStyleInternal(feature, true)
    },
    objectStyle(feature) {
      return this.objectStyleInternal(feature, false)
    },
    objectStyleInternal(feature, selected) {
      // this.printOnce(feature.values_)
      let resolution = this.resolution;

      let acuris_feature = feature.get('acuris')
      if (acuris_feature !== undefined) {
        if (acuris_feature === 'bay') {

          let count = parseInt(feature.get(feature.getKeys().filter(k => this.getScanName(k) === this.$root.scan.value)[0]))
          let area = parseFloat(feature.get('area'))

          return new Style({
            zIndex: selected ? 200 : 100,
            fill: new Fill({
              color: count ? this.countColor(count / area) : 'rgba(127,127,127,0.5)'
            }),
            stroke: selected ? new Stroke({ color: 'rgba(255,0,0,1)' }) : undefined
          })
        } else if (acuris_feature === 'block') {
          return new Style({
            zIndex: 102,
            stroke: new Stroke({
              color: 'rgba(255, 255 , 255, 1)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255 , 255, 0.01)',
            }),
            text: resolution < 1.6 ? this.createTextStyle(feature.get('name'), resolution) : undefined,
          })
        } else if (acuris_feature === 'row') {
          return new Style({
            zIndex: 101,
            stroke: resolution < 0.5 ? new Stroke({
              color: 'rgba(255, 255 , 255, 1)',
            }) : undefined,
            fill: new Fill({
              color: 'rgba(255, 255 , 255, 0.01)',
            })
          })
        } else if (acuris_feature === 'row-center') {
          let name_int = parseInt(feature.get('name')) 
          let res_dvblity = Math.round(30 * (resolution - 0.3))
          let name_dvbl = res_dvblity > 1 && name_int != 1 ? (name_int % res_dvblity == 0) : true
          let name_visible = name_dvbl && (resolution <= 0.6)
          return new Style({
            zIndex: 105,
            text: name_visible ? this.createTextStyle(feature.get('name'), resolution * 2.5, 'point') : undefined,
          })
        } else if (acuris_feature === 'orchard') {
          return new Style({
            zIndex: 104,
            fill: new Fill({
              color: 'rgba(1,1,1,0.01)',
            }),
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 1)',
              width: 4
            }),
            text: resolution >= 1.6 ? this.createTextStyle(feature.get('name'), resolution / 3) : undefined,
          })
        } else {
          this.printOnce("acuris feature not handled: " + acuris_feature)
        }
      } else {
        let power_feature = feature.get('power')
        if (power_feature !== undefined) {
          if (power_feature === "line") {
             // TODO working line
          }
        }
        let amenity_feature = feature.get('amenity')
        if (amenity_feature !== undefined) {
          // toilets, wind_machine, parking, shelter (natural, no subtype)
          if (amenity_feature !== "shelter") {
            return new Style({
              zIndex: 200,
              stroke: new Stroke({
                color: 'red',
                width: 0.5
              }),
              text: this.createTextStyle(amenity_feature, resolution * 1.5, 'point', 'red')
            });
          } else if (amenity_feature === "shelter") {
            let shelter_type_feature = feature.get('shelter_type')
            if (shelter_type_feature !== undefined) {
              if (shelter_type_feature === "natural") {
                return new Style({
                  zIndex: 200,
                  stroke: new Stroke({
                    color: '#05c202',
                    width: 0.75
                  }),
                  text: this.createTextStyle("natural shelter", resolution * 1.75, 'point', '#05c202')
                });
              }
            } else {
              return new Style({
                zIndex: 200,
                stroke: new Stroke({
                  color: '#ffffff',
                  width: 0.85
                }),
                text: this.createTextStyle("artificial shelter", resolution * 1.85, 'point', '#ffffff')
              });
            }
          }
        }
        let barrier_feature = feature.get('barrier')
        if (barrier_feature !== undefined) {
          if (barrier_feature === "gate") {
            return new Style({
              zIndex: 200,
              stroke: new Stroke({
                color: 'red',
                width: 0.5
              }),
              text: this.createTextStyle("gate", resolution * 1.5, 'point', 'red')
            });
          } else {
            return new Style({
              zIndex: 100,
              fill: new Fill({
                color: 'rgba(1,1,1,0.01)',
              }),
              stroke: new Stroke({
                color: '#F0F050',
                width: 1
              }),
            });
          }
        }


        // console.log("non acuris feature, values:", Object.keys(feature.values_))
        // console.log("geo:", feature.values_["geometry"]);
        // console.log("geo:", feature.getGeometry().getType(), typeof(feature.getGeometry().getType()));
        // let geoType = feature.getGeometry().getType();

        // console.log(elements.join(''));
        // let keyTags = feature.values_.filter(e => e !== 3
        // data.filter(({ type }) => filters.has(type));)

        // console.log(Object.keys(feature.values_))
        // console.log(typeof(Object.keys(feature.values_)))

        // let keyTags = Object.keys(feature.values_).filter(element => {
        //   // 👇️ using AND (&&) operator
        //   return element !== "area" || element !== "geometry";
        // });
        // let tagString = keyTags.join(',');

        // if (geoType == "LineString") {
        //   // return new Style({
        //   //   zIndex: 106,
        //   //   fill: new Fill({
        //   //     color: 'rgba(1,1,1,0.01)',
        //   //   }),
        //   //   stroke: new Stroke({
        //   //     color: 'rgba(0, 255, 100, 1)',
        //   //     width: 2
        //   //   }),
        //   //   text: this.createTextStyle(tagString, resolution * 0.75),
        //   // })
        // } else if (geoType == "Point") {
        //   // return new Style({
        //   //   zIndex: 106,
        //   //   image: new Circle({
        //   //     radius: 2,
        //   //     fill: new Fill({color: 'rgba(0, 255, 100, 1)'})
        //   //   }),
        //   //   text: this.createTextStyle(tagString, resolution * 0.75, 'point'),
        //   // })
        // }
        // //.getGeometry().getType()
        // console.log("geo:", typeof(feature.values_["geometry"]))

        // this.printOnce(feature)
        // get centroid of feature !!

        // get list of features
        // compare features to dict
        // decide which feature it is?
      }
      // TODO handle else of non acuris non dict features here
      return null;
    },
  }
}

let memoize = function (factory, ctx) {
  const cache = {};
  return function (key) {
    if (!(key in cache)) {
      cache[key] = factory.call(ctx, key);
    }
    return cache[key];
  };
};
let colorToRGBA = (function () {
  let canvas = document.createElement('canvas');
  canvas.width = canvas.height = 1;
  let ctx = canvas.getContext('2d');

  return memoize(function (col) {
    ctx.clearRect(0, 0, 1, 1);
    // In order to detect invalid values,
    // we can't rely on col being in the same format as what fillStyle is computed as,
    // but we can ask it to implicitly compute a normalized value twice and compare.
    ctx.fillStyle = '#000';
    ctx.fillStyle = col;
    const computed = ctx.fillStyle;
    ctx.fillStyle = '#fff';
    ctx.fillStyle = col;
    if (computed !== ctx.fillStyle) {
      return; // invalid color
    }
    ctx.fillRect(0, 0, 1, 1);
    let d = ctx.getImageData(0, 0, 1, 1).data
    return { red: d[0], green: d[1], blue: d[2], alpha: d[3] };
  });
})();

</script>

<!-- ----------------------------------------------------------STYLE---------------------------------------------------------- -->

<style lang="scss">

[class^="icon-"],
[class*=" icon-"] {
  text-align: center;
  min-width: 16px;
  margin: 0 7px;
}

</style>

<style lang="scss" scoped>

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.prompt {
  position: absolute;
  text-align: center;

  background-color: lightgray;
  border-radius: 0.5em;
  padding: 0.5rem 1rem 1rem 1rem;
}

.prompt h4 {
  margin-top: 16px;
}

.prompt.block-prompt {
  top: calc(50% - 4rem);
  right: calc(50% - 17rem);
}

.prompt.print-prompt {
  bottom: calc(12% - 4rem);
  left: calc(19.5% - 17rem);
}

#panel-hov {
  order: 2;

  z-index: 3;

  height: fit-content;
  margin: 0;
  background: rgba(60, 60, 60, 0.7);
  color: white;
  border: 0;

  border-radius: 0 16px 16px 0;
  padding: 18px 16px 8px 16px;
}

#panel-transition {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.panel-group {
  position: absolute;
  left: 0;
  top: 61px;
  pointer-events: none;
  height: -webkit-fill-available; 
  // height: calc(100% - 61px); // TODO change calc to include current navbar height
}

#panel-sel {
  order: 1;

  pointer-events: auto;
  display: flex;
  z-index: 4;

  width: 24.4vw;
  height: -webkit-fill-available;

  vertical-align: baseline;
  padding: 20px;
  border: 2vh;

  background: #f2f2f2;
  color: black;
  filter: drop-shadow(0 0 6px black);

  overflow: hidden;
}

#panel-subgroup {
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: start;
  // flex-direction: column-reverse;
}

.flex-col {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  justify-content: center;
}

.info-grid {
  order: 1;

  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
}

.info-grid .info-item {
  display: flex;
  flex-direction: column;
}

.info-item .average {
  padding: 5px 10px;
  border-radius: 10px;
  font-style: italic;
  display: flex;
  flex-direction: column;
}

.info-item .average span {
  font-style: normal;
  font-size: large;
}

.info-item .stats {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-even;
  padding-top: 5px;
}

.info-item .stats .stat-text {
  order: 2;
}

#panel-sel .bay-img-link {
  order: 2;

  display: flex;
  flex-grow: 1;

  flex-direction: column;
  justify-content: end;
  height: auto;
  max-height: fit-content;
}

#panel-sel .bay-img {
  order: 2;
  width: 100%;
  max-width: unset;
}

#panel-sel .panel_expand {
  position: absolute;
  right: -20px;
  top: 50%;

  background: #f2f2f2;
  width: 20px;
  height: 20px;

  padding: 15px 0 30px 0;
  border-radius: 0 5px 5px 0;
  text-align: center;
}

#gradient_group {
  pointer-events: auto;
  order: 3;
  display: flex;
}

.gradient-hov-2 {
  position: absolute;
  top: 4.3em;
  right: 0.5em;

  pointer-events: auto;

  z-index: 2;

  width: 300px;
  height: 50px;

  border-radius: 12px;
  background: white;

  transition: opacity 0.5s;
}

.gradient-hov-2 .slider {
  padding: 16px 12px 16px 12px;
}

.gradient-hov-2 .vue-slider-dot-tooltip-inner-top::after {
  top: -13%;
  transform: translate(-50%, 0);
  transform: scale(1, -1);
}

.vue-slider-dot-tooltip-inner-top::after {
  top: -13%;
  transform: translate(-50%, 0);
  transform: scale(1, -1);
}

.gradient-key {
  pointer-events: auto;

  margin-left: 0.5em;
  margin-bottom: 0.7em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 2;

  width: 150px;
  height: 25px;

  border-radius: 4px;
}

.gradient-key-2 {
  position: absolute;
  top: 4.3em;
  right: 0.5em;

  pointer-events: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 2;

  width: 150px;
  height: 25px;

  border-radius: 4px;
}

.gradient-key .key-item {
  display: flex;
  flex: 1;
  color: black;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 1.5em;
}

.gradient-key-2 .key-item {
  display: flex;
  flex: 1;
  color: black;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 1.5em;
}

.gradient-key .key-item.left {
  border-top-left-radius: 16%;
  border-bottom-left-radius: 16%;
  color: white;
  background-color: rgb(255, 213, 0, 0.5);
}

.gradient-key-2 .key-item.left {
  border-top-left-radius: 16%;
  border-bottom-left-radius: 16%;
  color: white;
  background-color: rgb(255, 213, 0, 0.5);
}

.gradient-key .key-item.middle {
  color: white;
  background-color: rgb(0, 255, 0, 0.5);
}

.gradient-key-2 .key-item.middle {
  color: white;
  background-color: rgb(0, 255, 0, 0.5);
}

.gradient-key-2 .key-item.right {
  border-top-right-radius: 16%;
  border-bottom-right-radius: 16%;
  color: white;
  background-color: rgba(0, 0, 255, 0.5);
}

span.vert-divider {
  display: inline-block;
  border-left: 3px solid #ccc;
  margin: 0 5px;
}

// transitions --------------------------------------------------

.panel-slide {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.panel-slide-enter-active {
  transition: all .3s ease;
}

.panel-slide-leave-active {
  transition: all .3s ease;
}

.panel-slide-enter,
.panel-slide-leave-to {
  transform: translateX(-24.4vw);
}

.panel-slide-leave-to {
  opacity: 0;
}

// tablet H aspect ratio
// @media (max-aspect-ratio: 5/3) and (min-aspect-ratio: 1/1) {
//   #panel-sel {
//     background: red;
//   }
// }

// tablet H aspect ratio
// @media (max-aspect-ratio: 5/3) and (min-aspect-ratio: 1/1) {
  
@media (max-aspect-ratio: 8/5) and (min-aspect-ratio: 1/1) {
  #panel-sel {
    width: 33vw;
    padding: 8px;
  }

  #panel-sel .info-grid {
    flex-direction: column;
    padding-bottom: 0.5rem;
  }

  #panel-sel .info-item {
    flex-direction: row;
  }

  #panel-sel .stats {
    text-align: right;
    padding-right: 16px;
  }

  #panel-sel .bay-img-link {
    flex-grow: unset;
  }

  .vert-divider {
    border-bottom: 3px solid #ccc;
    margin: 5px 0;
  }
}

// tablet V aspect ratio
@media (max-aspect-ratio: 1/1) {
  .panel-group {
    left: 0;
    top: unset;
    bottom: 0;
    width: 100%;
    border: red;
    height: auto;
  }

  .vue-slider-dot-tooltip-inner {
    font-size: 1.3rem;
  }

  #panel-transition {
    flex-direction: column;
    width: 100%;
    overflow: visible;
  }

  #panel-sel {
    order: 2;

    width: -webkit-fill-available;
    height: 40vh;
  }

  #panel-sel .flex-col {
    flex-direction: row;
    width: -webkit-fill-available;
  }

  #panel-sel .info-grid {
    flex-direction: column;
  }

  #panel-sel .info-item {
    flex-direction: row;
  }

  #panel-sel .info-item.block {
    align-self: center;
    flex-direction: row;
  }

  #panel-sel .stats {
    text-align: right;
    padding-right: 16px;
  }

  #panel-sel .info-item.block .average {
    display: none;
  }

  #panel-sel .info-item.block .stats {
    display: none;
  }

  #panel-sel .bay-img-link {
    flex-grow: unset;
  }

  #panel-sel .bay-img {
    width: auto;
  }

  .vert-divider {
    border-bottom: 3px solid #ccc;
    margin: 5px 0;
  }

  #panel-subgroup {
    order: 1;
  }

  .info-item .stats div {
    display: flex;
    flex-direction: row-reverse;
  }

  .bay-img-link img {
    height: 100%;
  }

  #panel-hov {
    display: none;
  }

  .panel-slide {
    flex-direction: column;
    width: 100%;
  }

  .panel-slide-enter,
  .panel-slide-leave-to {
    transform: translateY(40vh);
  }
}

@media print{ 
  body {
    zoom: 10%; /* Zoom level for printing */
    margin: 0px; /* Remove margin */
    text-align: center; /* Center the content */
  }

  .panel-group {
    display: none;
    // border-color: red;
    // border-style: dashed;
  }

  #panel-hov {
    display: none;
  }

  html, body {
    // width: 10mm;
    // height: 100%;
    text-align: center;
    // align-self: center;
    // height: 210mm; // A4 Paper width
  }

  .gradient-key-2 {
    display: none;
  }

  .gradient-hov-2 {
    display: none;
  }

  .gradient-key {
    display: none;
  }
}

// mobile V aspect ratio
// @media (max-aspect-ratio: 16/9) {
// }
</style>